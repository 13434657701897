<template>
    <div id="nasional-report-status-input" data-app>
      <div class="gutter-b">
          <b-button v-b-toggle.collapse-filter variant="white" size="lg" class="bg-white font-weight-bolder w-100">Filtering Data</b-button>
          <b-collapse :visible="visible" id="collapse-filter">
              <ValidationObserver ref="formAdd">
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <div class="card card-custom card-stretch">
                      <div class="card-body d-flex p-0 bg-white">
                          <div class="flex-grow-1 p-8 card-rounded bgi-no-repeat d-flex align-items-center">  
                              <div class="row w-100">
                                  <div class="col-12 col-sm-6">
                                      <validationProvider name="Data" rules="required" v-slot="{ valid, errors }">
                                      <b-form-group
                                      id="input-group-1"
                                      label="Data:"
                                      label-for="input-1"
                                      :state="valid"
                                      :invalid-feedback="errors[0]"
                                      class="m-0"
                                      >
                                          <b-form-select2
                                          id="input-1"
                                          v-model="filter.kategori"
                                          variant="solid"
                                          size="lg"
                                          :options="opsData"
                                          placeholder="Silahkan pilih Data"
                                          :state="valid"
                                          ></b-form-select2>
                                      </b-form-group>
                                      </validationProvider>
                                  </div>           
                                  <div class="col-12 col-sm-6">
                                      <validationProvider :name="`Periode`" v-slot="{ errors, valid }">
                                      <b-form-group
                                      id="input-group-4"
                                      label="Periode:"
                                      label-for="input-4"
                                      class="m-0"
                                      :invalid-feedback="errors[0]"
                                      :state="valid"
                                      >
                                          <b-button 
                                          type="button"
                                          id="input-4"
                                          class="form-control form-control-solid text-left" 
                                          variant="outline-secondary" 
                                          size="lg"
                                          @click="modalDate = true">{{ showDate }}
                                          </b-button>
                                      </b-form-group>
                                      </validationProvider>
                                  </div>
                                  <template v-if="kategoryFarm.includes(filter.kategori)">
                                    <div class="col-12 col-sm-6">
                                      <validationProvider :name="`Farm`" rules="required" v-slot="{ errors, valid }">
                                      <b-form-group 
                                      id="input-group-5" 
                                      label="Farm:"
                                      label-for="input-5"
                                      :invalid-feedback="errors[0]"
                                      :state="valid">
                                          <treeselect
                                          id="input-5"
                                          :multiple="true"
                                          required
                                          :options="opsFarm"
                                          :normalizer="normalizer"
                                          :flatten-search-results="true"
                                          placeholder="Silahkan pilih Farm"
                                          v-model="filter.farm"
                                          />
                                      </b-form-group>
                                      </validationProvider>
                                    </div>
                                  </template>
                                  <template v-if="kategoryHatchery.includes(filter.kategori)">
                                    <div class="col-12 col-sm-6">
                                      <validationProvider :name="`Hatchery`" rules="required" v-slot="{ errors, valid }">
                                      <b-form-group 
                                      id="input-group-6" 
                                      label="Hatchery:"
                                      label-for="input-6"
                                      :invalid-feedback="errors[0]"
                                      :state="valid">
                                          <treeselect
                                          id="input-6"
                                          :multiple="true"
                                          required
                                          :options="opsHatchery"
                                          :normalizer="normalizer"
                                          :flatten-search-results="true"
                                          placeholder="Silahkan pilih Hatchery"
                                          v-model="filter.hatchery"
                                          />
                                      </b-form-group>
                                      </validationProvider>
                                    </div>
                                  </template>
                              </div>
                          </div>
                      </div>
                      <div class="card-footer">
                          <button  
                          type="submit"
                          ref="kt_submit"
                          class="btn btn-primary font-weight-bold"
                          >
                          Tampilkan
                          </button>
                      </div>
                  </div>
              </b-form>
              </ValidationObserver>
          </b-collapse>
      </div>
  
      <component v-if="curComponent" :sdata="filter" v-bind:is="curComponent"></component>
  
      <v-dialog
          ref="dialogDate"
          v-model="modalDate"
          persistent
          width="290px"
      >
          <v-date-picker
          v-model="dates"
          scrollable
          range
          color="bg-primary"
          header-color="bg-primary"
          >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="modalDate = false"
          >
              Cancel
          </v-btn>
          <v-btn
              text
              color="primary"
              @click="setDate()"
          >
              OK
          </v-btn>
          </v-date-picker>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  
  import PerusahaanService from "@/core/services/api/master/perusahaan.service";
  import ErrorService from "@/core/services/error.service";
  
  import TableDinamis from "@/view/pages/nasional/report/_status-input/TableDinamis.vue"

  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {
    name: "nasional-report-status-input",
    components: {
      TableDinamis,
      Treeselect
    },
    data() {
      return {
          show: true,
          modalDate: false,
          isBusy: true,
          dates: [],
          visible: true,
          filter: {
              tgl_awal: null,
              tgl_akhir: null,
              kategori: null,
              farm: [],
              hatchery: [],
              title: null
          },
          curComponent: null,
          kategoryFarm: ['chickin', 'deplesi', 'collecting-he', 'transfer', 'feed-in-take', 'afkir'],
          kategoryHatchery: ['setting-he', 'pullchick', 'send-doc'],
          opsData: [
              { value: null, text: "Silahkan pilih Data" },
              { value: 'chickin', text: "Chickin" },
              { value: 'deplesi', text: "Depelsi" },
              { value: 'collecting-he', text: "Collecting HE" },
              { value: 'transfer', text: "Transfer" },
              { value: 'feed-in-take', text: "Feed In Take" },
              { value: 'afkir', text: "Afkir" },
              { value: 'setting-he', text: "Setting HE" },
              { value: 'pullchick', text: "Pullchick" },
              { value: 'send-doc', text: "Distribusi" },
          ],
          opsFarm: [],
          opsHatchery: [],
      }
    },
    computed: {
      ...mapGetters(["currentUser"]),
      showDate() {
          return (this.filter.tgl_awal || this.filter.tgl_akhir ? `${this.filter.tgl_awal} ~ ${this.filter.tgl_akhir}` : 'Silahkan klik untuk pilih tanggal')
      },
        opsDataFilter () {
            return this.opsData.filter((item) => {
                return item.theme == this.filter.display ||
                    item.theme == null
            });
        }
    },
    methods: {      
      setDate() {
          if (this.dates.length < 1) {
              ErrorService.message({
                  action: 'Peringatan',
                  message: 'Silahkan pilih tanggal terlebih dahulu',
              })
              return;
          }
          if (this.dates.length < 2) {
              this.dates.push(this.dates[0])
          }
          this.dates.sort()
          this.filter.tgl_awal = this.dates[0]
          this.filter.tgl_akhir = this.dates[1]
          this.modalDate = false
          this.errorDate = false
      },
      normalizer(node) {
        if (!node) return
        return {
          id: node.value,
          label: `${node.text}`,
          children: node.childrens,
        }
      },
      getFarm() {
        PerusahaanService.getPerusahaanFarm()
          .then((res) => {
              if (!res.data.status) {
                  ErrorService.message(res.data)
                  this.opsFarm = []
                  return;
              }
              this.opsFarm = res.data.data
          })
          .catch((err) => {
              this.opsFarm = []
              ErrorService.status(err)
          })
      },
      getHatchery() {
        PerusahaanService.getPerusahaanHatchery()
          .then((res) => {
              if (!res.data.status) {
                  ErrorService.message(res.data)
                  this.opsHatchery = []
                  return;
              }
              this.opsHatchery = res.data.data
          })
          .catch((err) => {
              this.opsHatchery = []
              ErrorService.status(err)
          })
      },
      removeSpinner(button) { 
        button.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      },
      onSubmit(evt) {
        evt.preventDefault()
        this.$refs.formAdd.validate().then(success => {
          if (!success) {
              ErrorService.message({
                action: 'Peringatan',
                message: 'Silahkan lengkapi form data terlebih dahulu',
              })
              return;
          }
          
          // set spinner to submit button
          const submitButton = this.$refs["kt_submit"];
          submitButton.classList.add("spinner", "spinner-light", "spinner-right");

          const findTitle = this.opsData.find((itm) => itm.value == this.filter.kategori)
          this.filter.title = findTitle.text

          this.show = false
          this.curComponent = null
          this.$nextTick(() => {
            this.show = true
            this.curComponent = "TableDinamis"
          })
        
          // dummy delay
          setTimeout(() => {
              this.removeSpinner(submitButton)
              this.visible = true
              this.$nextTick(() => {
                  this.visible = false
              })
  
              window.scrollTo({
                  top: 100,
                  left: 0,
                  behavior: 'smooth'
              });
          }, 500);
        })
      },
      onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        
          this.filter.kategori= null
          this.filter.tgl_awal= null
          this.filter.tgl_awal= null
          this.filter.farm= []
          this.filter.hatchery= []
  
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Status Input" }]);
      if (!this.currentUser.menu.statistik) {
        return this.$router.push({ name: 'dashboard'}).catch(()=>{})
      }
      this.getFarm()
      this.getHatchery()
    },
  };
  </script>