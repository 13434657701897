<template>
  <div id="report-status-input-dinamis" data-app>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ sdata.title | title }}
          </h3>
        </div>
      </div>

      <!--begin::Table-->
        <div class="card-body pt-0 pb-0">
          <v-data-table
            :headers="fields"
            :items="items"
            hide-default-footer
            class="elevation-1"
            disable-sort
            :loading="isBusy"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.tanggal`]="{ item }">
              <span>{{ item.tanggal | dateIndonesia }}</span>
            </template>
            <template v-slot:[`item.tanggal_setting`]="{ item }">
              <span>{{ item.tanggal_setting | dateIndonesia }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <b-badge :variant="item.color">{{ item.status }}</b-badge>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <div v-if="item._status == 170" class="ml-auto">
                    <span 
                    v-b-tooltip.hover title="Edit"
                    @click="openDialogForm(item, 'edit')"
                    class="svg-icon p-2 m-0 svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                        <!--end::Svg Icon-->
                    </span>
                    
                    <span 
                    v-b-tooltip.hover title="Hapus"
                    @click="deletePopupData(item)"
                    class="svg-icon p-2 m-0 svg-icon-danger">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Trash.svg"/>
                        <!--end::Svg Icon-->
                    </span>
                </div>
                <div v-if="item._status == 200 || item._status == 52" class="ml-auto">
                    <span 
                    v-b-tooltip.hover title="Ajukan Perbaikan"
                    @click="openDialogForm(item, 'perbaikan')"
                    class="svg-icon p-2 m-0 svg-icon-warning">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Unlock.svg"/>
                        <!--end::Svg Icon-->
                    </span>

                    <span 
                    v-if="item.last_versioning_count"
                    v-b-tooltip.hover title="Batalkan Perbaikan"
                    @click="openDialogForm(item, 'pembatalan')"
                    class="svg-icon p-2 m-0 svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Trash.svg"/>
                        <!--end::Svg Icon-->
                    </span>
                </div>
            </template>
          </v-data-table>
        </div>
        <div class="card-footer">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage" align="center"></b-pagination>
        </div>
      <!--end::Table-->  
    </div>

    <v-dialog v-model="dialogDetail" persistent scrollable max-width="500px">
      <component v-if="curComponent" :sdata="sdataForm" @close-modal="closeDialogForm" v-bind:is="curComponent"></component>
    </v-dialog>

    <v-dialog
    v-model="dialogDelete"
    max-width="300">
        <v-card>
            <v-card-title class="headline">Hapus data {{ sdata.title }}</v-card-title>

            <v-card-text>
                Apakah anda yakin akan menghapus data? data yang dihapus tidak dapat dikembalikan lagi
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>

            <b-button
            variant="link-success"
            class="m-3"
            @click="dialogDelete = false">
                BATAL
            </b-button>

            <b-button
            variant="link-danger"
            class="m-3"
            @click="onDelete">
                HAPUS
            </b-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReportService from "@/core/services/api/nasional/report.service";
import ErrorService from "@/core/services/error.service";
import PopulasiService from "@/core/services/api/nasional/populasi.service";
import ProduksiService from "@/core/services/api/nasional/produksi.service";
import DistribusiService from "@/core/services/api/nasional/distribusi.service";

import FormEditChickin from "@/view/pages/nasional/populasi/form/edit/_Chickin.vue";
import FormEditDeplesi from "@/view/pages/nasional/populasi/form/edit/_Deplesi.vue";
import FormEditFeedInTake from "@/view/pages/nasional/populasi/form/edit/_FeedInTake.vue";
import FormEditTransfer from "@/view/pages/nasional/populasi/form/edit/_Transfer.vue";
import FormEditProduksiHE from "@/view/pages/nasional/populasi/form/edit/_Produksi.vue";
import FormEditAfkir from "@/view/pages/nasional/populasi/form/edit/_Afkir.vue";
import FormPullChick from "@/view/pages/nasional/produksi/form/_PullChick.vue";
import FormSettingHE from "@/view/pages/nasional/produksi/form/_SettingHE.vue";
import FormEditSendDoc from "@/view/pages/nasional/distribusi/form/send-doc/_Edit.vue";

import FormPerbaikanPopulasi from "@/view/pages/nasional/populasi/form/edit/_AjukanPerbaikan.vue";
import FormPerbaikanProduksi from "@/view/pages/nasional/produksi/form/_AjukanPerbaikan.vue";
import FormPerbaikanDistribusi from "@/view/pages/nasional/distribusi/form/_AjukanPerbaikan.vue";

export default {
  name: "report-status-input-dinamis",
  props: {
    sdata:{
        type: [Object, Array],
        default: null
    },
  },
  components: {
    FormEditChickin,
    FormEditDeplesi,
    FormEditFeedInTake,
    FormEditTransfer,
    FormEditProduksiHE,
    FormEditAfkir,
    FormPullChick,
    FormSettingHE,
    FormEditSendDoc,
    FormPerbaikanPopulasi,
    FormPerbaikanProduksi,
    FormPerbaikanDistribusi
  },   
  data() {
    return {
      dialogDetail: false,
      sdataForm: null,
      curComponent: null,
      fromHapus: null,
      dataHapus: null,
      dialogDelete: null,
      dataDispaly: ['chickin', 'deplesi', 'collecting-he', 'transfer', 'feed-in-take', 'afkir', 'setting-he', 'pullchick', 'send-doc'],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      isBusy: true,
      fields: [{ value: "tanggal", text: "Tgl Recording" }, { value: "uraian", text: "Uraian" }, {value: "jumlah", text: "Jumlah"}, {value: "status", text: "Status"}],
      items: []
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    openDialogForm(data, action) {
      if(!this.dataDispaly.includes(this.sdata.kategori)) return

      this.sdataForm = {
          _idp: data._idp,
          _idc: data._idc || null,
          _sid: data._sid || null,
          _id: data._id,
          jumlah: data.jumlah,
          action: 'edit',
          kategori: this.sdata.kategori,
      }

      if(action == 'edit') {
        if(this.sdata.kategori == 'chickin') this.curComponent = "FormEditChickin"
        if(this.sdata.kategori == 'deplesi') this.curComponent = "FormEditDeplesi"
        if(this.sdata.kategori == 'collecting-he') this.curComponent = "FormEditProduksiHE"
        if(this.sdata.kategori == 'transfer') this.curComponent = "FormEditTransfer"
        if(this.sdata.kategori == 'feed-in-take') this.curComponent = "FormEditFeedInTake"
        if(this.sdata.kategori == 'afkir') this.curComponent = "FormEditAfkir"
        if(this.sdata.kategori == 'setting-he') this.curComponent = "FormSettingHE"
        if(this.sdata.kategori == 'pullchick') this.curComponent = "FormPullChick"
        if(this.sdata.kategori == 'send-doc') this.curComponent = "FormEditSendDoc"
      }

      if(action == 'perbaikan') {
          if(['chickin', 'deplesi', 'collecting-he', 'transfer', 'feed-in-take', 'afkir'].includes(this.sdata.kategori)) {
            this.curComponent = 'FormPerbaikanPopulasi'
          }

          if(['setting-he', 'pullchick'].includes(this.sdata.kategori)) {
            this.curComponent = 'FormPerbaikanProduksi'
          }

          if(['send-doc'].includes(this.sdata.kategori)) {
            this.curComponent = 'FormPerbaikanDistribusi'
          }

          this.sdataForm.action = 'perbaikan'
      }

      if(action == 'pembatalan') {
          if(['chickin', 'deplesi', 'collecting-he', 'transfer', 'feed-in-take', 'afkir'].includes(this.sdata.kategori)) {
            this.curComponent = 'FormPerbaikanPopulasi'
          }

          if(['setting-he', 'pullchick'].includes(this.sdata.kategori)) {
            this.curComponent = 'FormPerbaikanProduksi'
          }

          if(['send-doc'].includes(this.sdata.kategori)) {
            this.curComponent = 'FormPerbaikanDistribusi'
          }
          this.sdataForm.action = 'pembatalan'
      }

      this.dialogDetail = true
    },
    closeDialogForm(res) {
      if (res.status) {
        if (res.refresh) {
            this.getData()    
        }
        this.curComponent = null
        this.sdataForm = null
        this.dialogDetail = false
      }
    },
    deletePopupData( val) {
        this.dataHapus = val
        this.dialogDelete = true
    },
    onDelete () {
        if (! this.dataHapus) {
            return
        }
        let funcData
        if(!this.dataDispaly.includes(this.sdata.kategori)) return

        if(this.sdata.kategori == 'chickin') funcData = PopulasiService.deleteChickin({ populasi: this.dataHapus._idp, chickin: this.dataHapus._id })
        if(this.sdata.kategori == 'deplesi') funcData = PopulasiService.deleteDeplesi({_pid: this.dataHapus._idp, _id: this.dataHapus._idc, deplesi: this.dataHapus._id })
        if(this.sdata.kategori == 'collecting-he') funcData = PopulasiService.deleteProduksiEgg({_pid: this.dataHapus._idp, _id: this.dataHapus._idc, produksi: this.dataHapus._id })
        if(this.sdata.kategori == 'transfer') funcData = PopulasiService.deleteTransfer({_pid: this.dataHapus._idp, _id: this.dataHapus._idc, transfer: this.dataHapus._id })
        if(this.sdata.kategori == 'feed-in-take') funcData = PopulasiService.deleteFeedInTake({ _pid: this.dataHapus._idp, _id: this.dataHapus._idc, feedintake: this.dataHapus._id })
        if(this.sdata.kategori == 'afkir') funcData = PopulasiService.deleteAfkir({ _pid: this.dataHapus._idp, _id: this.dataHapus._idc, afkir: this.dataHapus._id })
        if(this.sdata.kategori == 'setting-he') funcData = ProduksiService.getStatusInputSettingHE({ _hid: this.dataHapus._idh, setting: this.dataHapus._id })
        if(this.sdata.kategori == 'pullchick') funcData = ProduksiService.getStatusInputPullchick({ setting: this.dataHapus._ids, pullchick: this.dataHapus._id })
        if(this.sdata.kategori == 'send-doc') funcData = DistribusiService.deleteSendDoc({ _hid: this.dataHapus._idh, distribusi: this.dataHapus._id })
        
        funcData.then((res) => {
            if (res.data.status) { 
              this.getData()              
            }
            ErrorService.message(res.data)
            this.dialogDelete = false
            this.dataHapus = null
        })
        .catch((err) => {
            this.dialogDelete = false
            this.dataHapus = null
            return ErrorService.status(err)
        })
    },
    changePage(val){
      if(this.currentPage == val) return
      this.isBusy = true
      this.items = []
      this.getData(val)
    },
    resetTable() {
      this.items = []
      this.totalRows = 0
      this.currentPage = 1
      this.fields = [{ value: "tanggal", text: "Tgl Recording" }, { value: "uraian", text: "Uraian" }, {value: "jumlah", text: "Jumlah"}, {value: "status", text: "Status"} ]
    },
    getData (page = 1, perPage = this.perPage) {
      let funcData
      if(!this.dataDispaly.includes(this.sdata.kategori)) return

      if(this.sdata.kategori == 'chickin') funcData = ReportService.getStatusInputChickin({page, per_page: perPage, ...this.sdata})
      if(this.sdata.kategori == 'deplesi') funcData = ReportService.getStatusInputDeplesi({page, per_page: perPage, ...this.sdata})
      if(this.sdata.kategori == 'collecting-he') funcData = ReportService.getStatusInputCollectingHE({page, per_page: perPage, ...this.sdata})
      if(this.sdata.kategori == 'transfer') funcData = ReportService.getStatusInputTransfer({page, per_page: perPage, ...this.sdata})
      if(this.sdata.kategori == 'feed-in-take') funcData = ReportService.getStatusInputFeedInTake({page, per_page: perPage, ...this.sdata})
      if(this.sdata.kategori == 'afkir') funcData = ReportService.getStatusInputAfkir({page, per_page: perPage, ...this.sdata})
      if(this.sdata.kategori == 'setting-he') funcData = ReportService.getStatusInputSettingHE({page, per_page: perPage, ...this.sdata})
      if(this.sdata.kategori == 'pullchick') funcData = ReportService.getStatusInputPullchick({page, per_page: perPage, ...this.sdata})
      if(this.sdata.kategori == 'send-doc') funcData = ReportService.getStatusInputDistribusi({page, per_page: perPage, ...this.sdata})
      
      funcData.then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.resetTable()
                this.isBusy = false
                return;
            }
            this.items = res.data.data.data
            this.fields = res.data.data.fields
            this.currentPage = res.data.data.paginate.current
            this.totalRows = res.data.data.paginate.total
            this.isBusy = false
        })
        .catch((err) => {
          this.resetTable()
          this.isBusy = false
          return ErrorService.status(err)
        })
    },
  },
  mounted() {
    this.getData()
  },
};
</script>

<style lang="scss">
#report-status-input-dinamis {
  .v-data-table {
    .v-data-table__wrapper {
      table {
        tbody {
          tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
            background: none !important;
          } 
        }
      }
    
      .v-data-table__mobile-row {
        min-height: 28px !important;
      }
    }
  }
}
</style>